
.VideoGalleryContainer{
  position: relative;
  display: flex;
  flex-flow: column;
  width: 1280px;
  background-color: white;
  /* 
  background: url('../assets/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  */
  padding-top: 1em;
  padding-bottom: 1em;
  /* border: 1px solid gray; */

}

.ControlContainer{
  display: flex;
  flex-flow: row;
  margin: auto;
  width: 100%;
  /* border: 1px solid gray; */
 

}

.VideoGalleryContainer > h3{
  color: var(--secondary);
  width: 1100px;
  margin: auto;
  padding: 1em;
  /* border: 1px solid gray; */

}

.VideoGalleryBox{
  position:relative;
  width: 1100px;
  margin: auto;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: center;
  /* border: 1px solid gray; */

}

.VideoGalleryBox > .VideoCard{
  transition: left 1s !important;
  transition-timing-function: ease !important;
  min-width: 240px;
  max-width: 240px;
  min-height: 250px;
  max-height: 250px;
  margin: 10px;
  cursor: pointer;
  /* border: 1px solid gray; */

}

.VideoGalleryBox > .VideoCard > .MediaContainer{
    position: relative;
    width: 240px;
    height: 150px;
    /* border: 1px solid gray; */
}

.VideoGalleryBox > .VideoCard > .MediaContainer > .PlayWatermark{
    position: absolute;
    z-index: 2;
    width: 70px;
    height: 70px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    /* border: 1px solid gray; */

}

.VideoGalleryBox > .VideoCard > .MediaContainer > .VideoMedia{
    z-index: 1;
    height: 150px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    /* border: 1px solid gray; */

}

.VideoGalleryBox > .VideoCard > .Content{
}

.VideoGalleryBox > .VideoCard:hover {
  box-shadow:0px 6px 8px 0px  var(--primary);

}

.VideoGalleryBox > .VideoCard:hover h3{
 color: var(--primary);
}

.VideoGalleryBox > .VideoCard > .Content > h3{
  display: block;
  margin: auto;
  font-size: 12pt;
}

.VideoGalleryBox > .VideoCard > .Content > p{
  font-size: 11pt;
  line-height: 1.4;
}

.VideoGalleryBox > .VideoCard > .Content > .VideoDatetime{
  display: block;
  margin-top: 3em;
  font-size: 7pt;
  color: gray;  
}


