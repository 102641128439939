
.BookingRuleContainer{
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 1280px;

  padding-top: 1em;
  padding-bottom: 4em;
  /* border: 1px solid gray; */
  /* background-color: rgb(245, 245, 245); */

}

.BookingRuleContainer > h2{
  color: var(--primary);
  width: 1100px;
  /* border: 1px solid gray; */

}

.BookingRuleBox{
  position:relative;
  overflow: hidden;
  width: 1100px;
  display: flex;
  flex-flow: column;
  align-items: center;
  /* border: 1px solid gray; */
}

.AccordionBox{
    width: 100%;
    border: 0;
    margin: 0;
    padding: 0;
}

.AccordionBox h3{
    font-size: 11pt;
    /* color: var(--secondary); */
}

.AccordionBox p{
  color: gray;
  line-height: 1.6;
}

.AccordionBox .info{
  color: var(--primary);
  line-height: 2.6;
  background-color: var(--shadow4);
}

.AccordionSummary{
 background-color: rgb(245, 245, 245) !important;
 height: 4em;
 border: 1px solid white;
}

.BookingSS{
  width: 70%;
  height: 300px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: contain;
  margin-bottom: 4em;
}


.BookingSSL{
  width: 70%;
  height: 500px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: contain;
  margin-bottom: 4em;
}