.HowToContainer{
    display: flex;
    flex-flow: column;
    width: 1100px;
    background-color: var(--shadow2);

    /* border : 1px solid black; */
  }

  .HowToContainer > .BoxHowto{
    display: flex;
    flex-flow: column;
    background-color: white;
    color: var(--primary);
    width: 100%;
    height : 310px;
    border-radius: 5px;
    padding: 1em;
    /* border : 1px solid black; */
    align-items: center;
    justify-content:center;
  }

  .HowToContainer > .BoxHowto > .Top{
    width: 100%;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content:center;
    /* border : 1px solid red; */
  }

  .HowToContainer > .BoxHowto > .Top >.IconBox{
    width: 210px;
    height : 180px; 
    display: flex;
    flex-flow: column;
    align-items: center;
    /* border : 1px solid lightgrey; */

  }

  .HowToContainer > .BoxHowto > .Top > .IconBox > p{
    margin: 0;
    text-align: center;
    font-size: 12pt;
    font-weight: bold;
    color: var(--secondary);
    /* border : 1px solid lightgrey; */
  }
  
  
  .HowToContainer > .BoxHowto > .Top > .IconBox > .IconStep{
    width: 170px;
    height : 150px; 
    border-radius: 2em;
    margin-top: 0.5em;
    background-repeat: no-repeat;
    background-size: 70%;
    background-position: center;
    /* border : 1px solid lightgrey; */
  }

  .HowToContainer > .BoxHowto > .Bottom{
    width: 85%;
    height: 20%;
    display: flex;
    flex-flow: row;
    justify-content:space-between;
    align-items: left;
    /* border : 1px solid red; */
  }

  .HowToContainer > .BoxHowto > .Bottom > .StartButton{
    width: 20%;
  }

  .HowToContainer > .BoxHowto > .Bottom > p{
    font-size: 11pt;
    color: var(--primary);
  }


