
.UnderConstruction{
  position: relative;
  display: flex;
  flex-flow: column;
  width: 1280px;
  height: 620px;
  /* background-color: var(--shadow4); */
  
  background: url('../assets/UnderConstruction.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
 
  padding-top: 1em;
  padding-bottom: 1em;
  /* border: 1px solid gray; */

}

.UnderConstruction > h1{
  color: var(--primary);
  width: 1040px;
  margin: auto;
  text-align: center;
  /* border: 1px solid gray; */

}
