.ContactContainer{
    position: relative;
    display: flex;
    flex-flow: column;
    width: 1280px;
    background-color: white;
    /*
    background: url('../assets/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    */
    padding: 1em;
  }
  
  .ContactContainer > .Top {
      padding: 1em;
      display: flex;
      flex-flow: row;
      /* height: 400px; */
      /* background-color: var(--shadow1); */
      /* border: 1px solid gray; */
  }
  
  .ContactContainer > .Top > .Left{
      padding: 2em;
      width: 50%;
      height: 100%;
      background-color: white;
      display: flex;
      flex-flow: column;
      /* border: 1px solid gray; */
  }

  .ContactContainer > .Top > .Left > div {
      /* border: 1px solid gray; */
      width: 100%;
      padding: 0.5em;
  }



  .ContactContainer > .Top > .Left > div > .contact_txt{
    width: 100%;
    /* border: 1px solid gray; */
    }
  
  
  .ContactContainer > .Top > .Left > h2{
    width: 100%;
    color: var(--primary);
    margin: 0;
    margin-bottom: 1em;
    /* display: table-cell;
    vertical-align: middle; */
    /* border: 1px solid gray; */
  }
  
  .ContactContainer > .Top > .Left > .CallRow {
    width: 100%;
    padding: 0.5em;
    display:flex;
    /* border-bottom: 1px dotted var(--primary); */
    margin-bottom: 1em;
  }
 
  .ContactContainer > .Top > .Left > .CallRow > .CallButton{
      width: 45%;
      border-radius: 10px;
      /* box-shadow:0px 3px 6px 0px  lightgray; */

    /* border: 1px solid gray; */

  }

  .ContactContainer > .Top > .Left > div > .CallButton > .ButtonWrap{
    display: flex;
    align-items: center;
  }

  .ContactContainer > .Top > .Left > div > .CallButton > .ButtonWrap > .icon > .iconColor{
    font-size: 30px;
    /* border: 1px solid gray; */
  }

  .ContactContainer > .Top > .Left > div > .CallButton > .ButtonWrap > .text {
    font-size: 13pt;
    color: var(--primary);
    padding-left: 0.5em;
    /* border: 1px solid gray; */
  }

  
  .ContactContainer > .Top > .Right{
    padding: 1em;
    width: 50%;
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;
    /* display: table-cell;
    vertical-align: middle; */
    /* border: 1px solid gray; */
  }

  .ContactContainer > .Bottom {
    display: flex;
    flex-flow: row;
    /* height: 400px; */
    /* background-color: var(--shadow1); */
    padding: 1em;
    /* border: 1px solid gray; */
}
  
  .ContactContainer > .Bottom > .Left{
      width: 50%;
      white-space: pre-line;
      color: gray;
      font-size: 10pt;
      tab-size: 2;
      line-height: 1.6;
      text-align: center;
      /* border: 1px solid gray; */
  
  }
  
  .ContactContainer > .Bottom > .Right{
    width: 50%;
    padding: 1.5em;
    color: gray;
    font-size: 10pt;
    tab-size: 2;
    line-height: 1.6;
    text-align: center;
    /* border: 1px solid gray; */
  }
  .ContactContainer > .Bottom > .Right > .FormWrapper {
    height: 100%;
    border-radius: 5px;
    padding: 2em;
    box-shadow:0px 3px 6px 0px  lightgray;
    /* border: 1px solid gray; */

  }

  .ContactContainer > .Bottom > .Right > .FormWrapper > h1{
    text-align: left;
    color: var(--primary);
    /* border: 1px solid gray; */

  }

  .ContactContainer > .Bottom > .Right > .FormWrapper > p{
    text-align: left;
    color: gray;
    white-space: pre-line;

  }


  