
.ProfileContainer{
  position: relative;
  display: flex;
  flex-flow: column;
  width: 1280px;
  background-color: white;
  /*
  background: url('../assets/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  */
  padding: 1em;

}

.ProfileContainer > .AboutContainer {
    display: flex;
    flex-flow: row;
    /* background-color: var(--shadow1);   */
    /* border: 1px solid gray; */
}

.ProfileContainer > .AboutContainer > .LeftBox{
    width: 30%;
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: center;

}

.ProfileContainer > .AboutContainer > .RightBox{
    width: 70%;
    padding: 1em;

}

.ProfileContainer > .AboutContainer >  .RightBox > p{
    /* white-space: pre-line; */
    color: gray;
    font-size: 9pt;
    tab-size: 2;
    line-height: 1.8;
    text-indent: 2em;
    /* display: inline; */

  /* border: 1px solid gray; */

}

.ProfileContainer > .AboutContainer >  .RightBox > p{
  /* white-space: pre-line; */
  color: gray;
  font-size: 9pt;
  tab-size: 2;
  line-height: 1.8;
  text-indent: 2em;
  /* display: inline; */

/* border: 1px solid gray; */

}

.ProfileContainer > .AboutContainer >  .RightBox > h1{
  color: var(--primary);
  /* border: 1px solid gray; */

}



.ProfileContainer > .VisionContainer {
    padding: 1em;
    display: flex;
    flex-flow: row;
    height: 400px;

    /* background-color: var(--shadow1); */
    /* border: 1px solid gray; */
}

.ProfileContainer > .VisionContainer > .LeftBox{
    width: 50%;
    /* background-color: var(--primary); */
    background-color: white;
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center;

}

.ProfileContainer > .VisionContainer > .RightBox{
    padding: 1em;
    width: 50%;
    height: 100%;
    background-color: white;

    /* display: table-cell;
    vertical-align: middle; */
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content:center;
    align-self: center;
    /* border: 1px solid gray; */
}


.ProfileContainer > .VisionContainer >  .RightBox > p{
    white-space: pre-line;
    color: gray;
    font-size: 10pt;
    tab-size: 2;
    line-height: 1.6;
    text-align: center;
    /* border: 1px solid gray; */

}

.ProfileContainer > .VisionContainer >  .RightBox > h1{
  color: var(--primary);
  /* margin: auto; */
  /* border: 1px solid gray; */

}

.ProfileContainer > .MissionContainer {
    padding: 1em;
    display: flex;
    flex-flow: row;
    height: 400px;
    /* background-color: var(--shadow3); */
    /* border: 1px solid gray; */
}

.ProfileContainer > .MissionContainer > .LeftBox{
    width: 50%;
    background-color: white;
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center;

}

.ProfileContainer > .MissionContainer > .RightBox{
    background-color: white;
    height: 100%;
    width: 50%;
    padding: 1em;
    /* display: table-cell;
    vertical-align: middle; */
    display: flex;
    flex-flow: column;
    /* align-items: center; */
    align-self: center;
    text-align: center;

    /* border: 1px solid gray; */
}


.ProfileContainer > .MissionContainer >  .RightBox > p{
    white-space: pre-line;
    color: gray;
    font-size: 10pt;
    tab-size: 2;
    line-height: 1.4;
    text-align: left;
    /* border: 1px solid gray; */

}

.ProfileContainer > .MissionContainer >  .RightBox > h1{
  color: var(--primary);
  margin: auto;
  /* border: 1px solid gray; */

}














.VideoBox > .VideoCard:hover h3{
 color: var(--primary);
}

.VideoBox > .VideoCard > .Content > h3{
  display: block;
  margin: 0;
}

.VideoBox > .VideoCard > .Content > p{
  font-size: 11pt;
  line-height: 1.4;
}

.VideoBox > .VideoCard > .Content > .VideoDatetime{
  display: block;
  margin-top: 1em;
  font-size: 9pt;
  color: gray;
}


