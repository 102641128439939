/* @font-face {
  font-family: "ubuntu";
  src: url('../ubuntu/Ubuntu-R.ttf');
}

*{
  font-family : "ubuntu" !important;
} */


.iconColor{
  color: var(--primary);
}

div{
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;    
}


.NavContainer{
  width: 100%;
  background-color: white;
  margin: auto;
  border-bottom: 1px solid #eeeeee;
}

.NavTop{
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 1280px;
  line-height: 30px;
  padding-left: 50px ;
  padding-right: 50px ;
  margin: auto;
  font-size: 10pt;
  color: var(--secondary);
  background-color: white;
  /* border-bottom: 1px solid #eeeeee; */

}

.NavTop > div{
  display: flex;
  align-items: center;
  margin-left: 1em;
  margin-right: 1em;

  color:  var(--secondary);
}

.NavTop > div > span{
  margin-left: 0.3em;
}

.Right{
  margin-left: auto !important;
}

.NavBottom{
  display: flex;
  align-items: center;
  width: 1280px;
  height: 60px;
  padding-left:50px;
  padding-right:50px;
  background-color: white;
  margin: auto;
  font-size: 10pt;
  font-weight: bold;
  color: var(--secondary);
  /* border-bottom: 1px solid #eeeeee; */
}

.logo_gh{
  height: 37px;
}

.NavBottom > div{
  display: flex;
  align-items: center;
  height: inherit;
}

.NavMenu{
  height: inherit;
}

.NavMenu:hover .dropdown-content{
  display: block;
}

.NavButton{
  display: flex;
  justify-content: center; /*horizontal align*/
  align-items: center; /*vertical align*/
  padding: 0.7em;

  cursor: pointer ;
  height: inherit;

  
}

/*set the home link size bigger to easier click*/
.NavButton > a{
  width: 100%;
  display : block;
  text-align: center;
  line-height: 4em;
  color: var(--secondary);
}

.NavButton:hover {
  color: var(--primary);
  border-bottom: 2px solid var(--primary);
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  width: 200px;
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.4);
  z-index: 1;
  /* margin-top: -1px; */
}

.dropdown-content > a{
  width: 100%;
  display : block;
  text-align: center;
  line-height: 4em;
  
}

.dropdown-content > a:hover{
  /* background-color: var(--shadow2); */
  /* border-bottom: 2px solid var(--primary); */
  color: var(--primary);
  background-color: var(--shadow4);
}

