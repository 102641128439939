.BookingContainer{
    display: flex;
    flex-flow: column;
    width: 1280px;
    /* background-color: var(--shadow2); */
    /*
    background: url('../assets/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    */
    padding-top: 1em;
    padding-bottom: 1em;

  }

.BookingBox{
  width: 90%;
  margin: auto;
}


.BookingBox > .Top{
  display: flex;
  flex: row;
  justify-content:space-between;
  align-items: center;
}

.BookingBox > .Bottom-Horizontal{
  display: flex;
  flex-flow: row;
}

.BookingBox > .Bottom-Horizontal > .Left{
  width: 60%;
  padding: 1em;
}

.BookingBox > .Bottom-Horizontal > .Right{
  display: flex;
  flex: column;
  width: 40%;
  padding: 1em;
}


.BookingBox > .Bottom-Vertical{
  display: flex;
  flex-flow: column;
}

.BookingBox > .Bottom-Vertical > .Left{
  width: 50%;
  padding: 1em;
}

.BookingBox > .Bottom-Vertical > .Right{
  display: flex;
  flex: column;
  width: 100%;
  padding: 1em;
}


.DateContainer{
  width: 60%;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.2);
  padding-top: 1em;
  text-align: center;

}

.TimeContainer{
  width: 90%;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.2);
  margin-left: auto;
  padding-top: 2em;
  padding-left: 2em;
  padding-right: 2em;
  /* text-align: center; */
}

.PatientDataContainer{
  width: 100%;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.2);
  padding: 1em;
  display: flex;
  flex-flow: column;
  justify-content: center;
  vertical-align: center;
}

.textfield{
  width: 90%;
  margin: 0.5em !important;
  font-size: "7px";
}


.DataForm{
  width:100%;
  display: flex;
  flex-flow: row;
}


.DataForm > .Left{
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 50%;
  padding: 1em;
}

.DataForm > .Right{
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 50%;
  padding: 1em;
}


.ButtonForm{
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  width: 100%;
  padding: 1em;
}



.DoctorCardMini{
  /* background-color: var(--shadow4); */
  width: 100%;
  /* height: 230px; */
  margin-bottom: 0.7em;
  border-bottom: 5px solid rgb(240, 240, 240);
}


.DoctorCardMini > .top{
  display: flex;
  flex-flow: row;
  padding: 1em;
}

.DoctorCardMini > .top > .photo{
  width: 10%;
  height: 20vh;
}


.DoctorCardMini > .top > .info > *{
  margin: 1em;
}

.DoctorCardMini > .top > .info > h3 , p{
  display: flex;
  align-items: center;
}


.DoctorCardMini > .bottom{
  display: flex;
  align-items: center;
  flex: row;
  width: 100%;
}

.DoctorCardMini > .bottom > div{
  width: 100%;
}

.DoctorCardMini > .bottom > div > .thead{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5em;
  background-color: var(--secondary);
  font-size: 10pt;
  font-weight: bold;
  color: white;
}


.DoctorCardMini > .bottom > div > .trow{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5em;
  border-bottom: 1px solid var(--shadow2);
  font-size: 9pt;
  color: gray;

}

/***** patient page *****/
.PatientBookingBox{
  width: 90%;
  display: flex;
  flex-direction: column;
}

.PatientBookingBox > .top{
  display: flex;
  flex-direction: row;
  height: 30vh;
}


.DoctorCard2{
  /* background-color: var(--shadow4); */
  width: 100%;
  /* height: 30%; */
  margin-bottom: 0.7em;
  border-bottom: 5px solid rgb(240, 240, 240);
}


.DoctorCard2 > .top{
  display: flex;
  flex-flow: row;
  padding: 1em;
}

.DoctorCard2 > .top > .photo{
  width: 20%;
  /* height: 20vh; */
}


.DoctorCard2 > .top > .info > *{
  margin: 1em;
}

.DoctorCard2 > .top > .info > h3 , p{
  display: flex;
  align-items: center;
}


.VerificationModal{
  display: block;
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  height: 300px;
  background-color: white;
  box-shadow: 24;
  padding: 1em;

}

.VerificationModal > div{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;;
  margin-top: 1em;
}

button > a {
 color: white;

}


.PatientHisContainer{
  display: flex;
  flex-flow: column;
  width: 1280px;
  align-items: center;
  /* background-color: var(--shadow2); */
  /*
  background: url('../assets/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  */
  padding-top: 1em;
  padding-bottom: 1em;

}

.PatientHisBookingBox{
  width: 90%;
  margin: auto;
}

.PatientHisBookingBox > .Bottom-Horizontal{
  display: flex;
  flex-flow: column;
}

.PatientHisBookingBox > .Bottom-Horizontal > .Left{
  width: 50%;
  color: var(--primary);
}

.PatientHisBookingBox > .Bottom-Horizontal > .Right{
  display: flex;
  flex-flow: column;
  width: 50%;
  /* padding: 1em; */

}

.PatientCard{
  display: flex;
  flex-flow: column;
  width: 80%;
  /* height: 180px; */
  /* height: 30%; */

  margin-bottom: 0.7em;
  background-color: white;
  border-bottom: 5px solid rgb(240, 240, 240);

}

.PatientCard > div {
  display: flex;
  align-items: center;
  padding: 0.15em;
  padding-left: 1em;
  height: 20%;
  color: var(--secondary);
}

.PatientCard > .odd{
  /* background-color: rgb(227, 227, 227); */
  background-color: var(--shadow4);
}


.PatientCard > div > p{
  width: 25%;
  margin: 0;
  margin-left: 1em;

}
