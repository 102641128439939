:root{
  /* --primary: #2b4ab1; */
  --primary: #2c9460;
  
  --secondary: #1c3f6e;
  --third: #197cfd;
  --fourth: #fdb519;
  --gradient1: linear-gradient(90deg, rgba(1,129,0,1) 0%, rgba(40,194,152,1) 100%); 
  --green:  rgb(20, 147, 20);

  
  --shadow1: #fffbef;
  --shadow2: #e4f2fa;
  --shadow3: #cfcfcf;
  --shadow4: #f0fffb;
  --gray: #f0f3f5;
  
}

@font-face {
  font-family: "ubuntu";
  src: url('./ubuntu/Ubuntu-R.ttf');
}

*{
  font-family : "Arial" !important;
}

a{
  color: black;
  text-decoration: none;
}


body {
  margin: 0;
  font-family: "ubuntu";
  /* -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Container{
  width: 90%;
  margin: auto;
}

.ContainerTop{
  width: 100%;
  margin: auto;
  background-color: white;
}

.Background{
  width: 100%;
  background-color: white;
}
