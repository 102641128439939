  .tabContainer{
    display: table-cell;
    vertical-align: middle;
    width: 1280px;
    padding-top: 3em;
    padding-bottom: 3em;
    color: var(--primary);
    background-color: white;
    /* 
    background: url('../assets/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    */
  }

  .chipContainer{
   width:90%;
   margin: auto;
   padding: 0.6em;
   margin-bottom: 0.5em;
  }

  .tabBox{
    width: 90%;
    margin: auto;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.4);
    font-size: 12pt;
    color: var(--secondary);

  }

  .tabHeader{
    display: flex;
    align-items: stretch;
    height: 50px;
  }

  .tabButton{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: inherit;  
    font-weight: 900;
    font-size: 11pt;
    cursor: pointer;
    border: none;
    background-color: white;
    color: var(--secondary);
  }
  
  .tabButtonActive{
    /* background-color: var(--primary); */
    /* background-image: linear-gradient(90deg, #2c9460 20%, rgb(0, 158, 92) 100%); */
    /* background-color: var(--secondary); */
    background-color: rgba(1,129,0,1);

    color: white;
    /* border-bottom: 1px solid white; */

  }

  .hide{
    display: none;
  }

  .tabContent{
    height: 400px;
    background: rgb(1,129,0);
    background: linear-gradient(90deg, rgba(1,129,0,1) 0%, rgb(51, 164, 83) 100%); 

    /* background: rgb(0,56,129);
    background: linear-gradient(90deg, rgba(0,56,129,1) 0%, rgba(0,123,212,1) 100%);  */

    background-color: rgba(1,129,0,1);
    /* background-color: var(--primary); */
    /* background-image: linear-gradient(45deg, #2c9460 80%, rgb(54, 186, 159) 100%); */
    color: white;
    padding: 1em;
    /* border : 1px solid black; */
  }

  .box{
    display: flex;
    background-color: rgb(255, 255, 255);
    width: 100%;
    height : 300px;
    border-radius: 5px;
    padding: 1em;
    /* border : 1px solid black; */
    flex-flow: row;
    align-items: center;
    justify-content:space-evenly;
  }

  .tabContent > .BoxHowto{
    display: flex;
    flex-flow: column;
    background-color: white;
    color: var(--primary);
    width: 100%;
    height : 310px;
    border-radius: 5px;
    padding: 1em;
    /* border : 1px solid black; */
    align-items: center;
    justify-content:center;
  }

  .tabContent > .BoxHowto > .Top{
    width: 100%;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content:center;
    /* border : 1px solid red; */
  }

  .tabContent > .BoxHowto > .Top >.IconBox{
    width: 210px;
    height : 180px; 
    display: flex;
    flex-flow: column;
    align-items: center;
    /* border : 1px solid lightgrey; */

  }

  .tabContent > .BoxHowto > .Top > .IconBox > p{
    margin: 0;
    text-align: center;
    font-size: 12pt;
    color: var(--secondary);

    /* border : 1px solid lightgrey; */
  }
  
  
  .tabContent > .BoxHowto > .Top > .IconBox > .IconStep{
    width: 170px;
    height : 150px; 
    border-radius: 2em;
    margin-top: 0.5em;
    background-repeat: no-repeat;
    background-size: 70%;
    background-position: center;
    /* border : 1px solid lightgrey; */
  }

  .tabContent > .BoxHowto > .Bottom{
    width: 85%;
    height: 20%;
    display: flex;
    flex-flow: row;
    justify-content:space-between;
    align-items: left;
    /* border : 1px solid red; */
  }

  .tabContent > .BoxHowto > .Bottom > .StartButton{
    width: 20%;
  }

  /* .tabContent > .BoxHowto > .Bottom > p{
    font-size: 12pt;
  } */




  table{
    height: 180px;
  }

  table, tr, td{
    width: 100%;
    /* border: 1px solid gray; */
  }

  .textfield{
    background-color: white;
    width: 100%;
  }

  .button{
    width: 80%;
    background-color: var(--third) !important;
  }

  .atau{
    display:table-cell;
    width: 150px;
    height: 2.5em;
    background-color: var(--fourth); 
    text-align: center;
    vertical-align: middle;
    border-radius: 5px;   
  }

  H3{
    height: 1em;
  }

  .leftBox{
    height: 250px;
    width: 30%;
    background: url('../assets/Dokter1.png');
    background-repeat: no-repeat;
    background-size: 160px;
    background-position: top;
    /* border: 1px solid gray; */
  }


.DoctorResult{
  width: 90%;
  margin: auto;
  margin-top: 2em;
  overflow: hidden;
  font-size: 12pt;
  color: var(--secondary);
}

.doctorCard{
  border-bottom: 10px solid var(--shadow4);
  margin-top: 2em;
  
}

.doctorCard > .top{
  display: flex;
  flex-flow: row;
}

.doctorCard > .top > .photo{
  width: 10%;
  height: 20vh;
}

.doctorCard > .top > .info > h3{
  margin: 0.5em;
}

.doctorCard > .top > .info > p{
  margin: 1em;
}

.doctorCard > .top > .info > button{
  margin: 1em;
}



.doctorCard > .top > .info > h3 , p{
  display: flex;
  align-items: center;
}

.doctorCard > .top > .info > h3{
  font-size: 12pt;
}

.doctorCard > .top > .info > p{
  font-size: 9pt;
}

.doctorCard > .bottom{
  display: flex;
  align-items: center;
  flex: row;
  width: 100%;
}

.doctorCard > .bottom > div{
  width: 100%;

}

.doctorCard > .bottom > div > .thead{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5em;
  background-color: var(--secondary);
  font-size: 10pt;
  font-weight: bold;
  color: white;
}


.doctorCard > .bottom > div > .trow{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5em;
  border-bottom: 1px solid var(--shadow2);
  font-size: 10pt;

}



.CautionBox{
  display: flex;
  align-items: center;
  background-color: var(--shadow4);
  color: var(--primary);
  padding: 0.5em;

}



.tabContent > .BoxAppoinment{
  display: flex;
  flex-flow: column;
  background-color: white;

  color: var(--primary);
  width: 100%;
  height : 310px;
  border-radius: 5px;
  padding: 1em;
  /* border : 1px solid black; */
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
}

.tabContent > .BoxAppoinmentEmpty{
  display: flex;
  flex-flow: column;
  background-color: white;
  color: var(--primary);
  width: 100%;
  height : 310px;
  border-radius: 5px;
  padding: 1em;
  /* border : 1px solid black; */
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  overflow-x: hidden;
}

.IconEmpty{
  width: 170px;
  height : 150px; 
  border-radius: 2em;
  margin-top: 0.5em;
  background-repeat: no-repeat;
  background-size: 60%;
  background-position: center;
  /* border : 1px solid lightgrey; */
}