
/* information */
.ModalDoctorStyle{
  overflow: scroll
}

.ModalDoctorContainer{
  display: block;
  position: absolute;
  text-align: left;
  left: 50%;
  transform: translateX(-50%);
  width: 700px;
  background-color: white;
  box-shadow: 24;
}

.DoctorPhotoContainer{
  display: flex;
  /* position: relative; */
  /* align-items: center; */
  justify-content: center;
  overflow: hidden;
  flex-wrap: nowrap;
  white-space: nowrap;
  width: 100%;
  /* border: 1px solid gray; */
  height: 50vh;
  background-color: white;
  margin: auto;
}

.DoctorPhoto{
  /* position: absolute; */
  /* left: 0px; */
  width: 70%;
  text-align: center;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center top;
  transition: left 1s;
  transition-timing-function: ease;
}

.ModalDoctorContainer > .ModalContent{
  padding: 1.5em;
}

.ModalDoctorContainer > .TitleBar{
  width: 100%;
  text-align: right;
}

.ModalDoctorContainer > .ModalContent > .Specialist{
    display: block;
    /* margin-top: 1em; */
    font-size: 13pt;
    font-style: italic;
    color: gray;  
  }
  

.ModalDoctorContainer > .ModalContent > .ContentParagraph{
  white-space: pre-line;
  line-height: 1.6;
}


 .DoctorScheduleWeek{
    width: 100%;
    height: 20%;
    display: flex;
    flex-flow: row;
    justify-content:space-between;
    align-items: left;
    /* border : 1px solid red; */
  }

  .DoctorScheduleWeek > div{
    width: 100%;
  }
  
.ScheduleWeekHeader{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2em;
    background-color: var(--secondary);
    font-size: 10pt;
    font-weight: bold;
    color: white;
  }

  .DoctorScheduleWeek .trow{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5em;
    border-bottom: 1px solid var(--shadow2);
    font-size: 10pt;
  
  }
  
  
