
.FooterContainer{
  display: flex;
  flex-flow: column;
  width: 1280px;
  background-color: var(--shadow4);
  color: gray;
  /* 
  background: url('../assets/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  */
  /* padding-top: 1em;
  padding-bottom: 1em; */

}

.FooterContainer > .Top {
    width: 90%;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-self: center;
    
}

.FooterContainer > .Top > .Box{
    display: flex;
    flex-flow: column;
    width: 25%;
    height: 300px;
    background-color: transparent;
    padding: 2em;
    /* border: 1px solid gray; */

}

.FooterContainer > .Top > .Box > .Title{
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    font-weight:bolder;
    color: var(--secondary);
    /* border: 1px solid gray; */

}

.FooterContainer > .Top > .Box > .Content{
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    /* border: 1px solid gray; */

}

.logo_gh_footer{
    height: 40px;
}

.logo_mandiri_footer{
    width: 70%;
    /* margin-top: 0.2em; */
    /* margin-bottom: 0.5em; */
}

.logo_kars_footer{
    width: 50%;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.logo_bpjs_footer{
    width: 90%;
    margin-top: 1em;
    margin-bottom: 1em;
}

.FooterContainer > .Top >.Box > .Content > p {
    font-size: 10pt;
    line-height: 1.6;
}


.FooterContainer > .Top >.Box > .Content > .FooterButton{
    width: 100%;
    font-size: 10pt;
    color: var(--secondary);
    border-radius: 0;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
}

.FooterContainer > .Top >.Box > .Content > .FooterButton > .icon{
    font-size: 11pt;
    width: 20%;
    /* border: 1px solid gray; */

}

.FooterButton .iconColor{
    color: var(--secondary);
}

.FooterContainer > .Top >.Box > .Content > .FooterButton > .text{
    font-size: 10pt;
    width: 90%;
    text-align: left;
    /* border: 1px solid gray; */


}

.FooterContainer > .Bottom {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    padding: 2em;
    background-color: white;
    font-size: 10pt;

}
