
.PartnerContainer{
  position: relative;
  display: flex;
  flex-flow: column;
  width: 1280px;
  /* height: 720px; */
  /* background-color: var(--shadow1); */
  /* 
  background: url('../assets/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  */
  padding-top: 1em;
  padding-bottom: 3em;
  /* border: 1px solid gray; */

}

.PartnerContainer > .ControlContainer{
  display: flex;
  flex-flow: row;
  margin: auto;
  margin-top: 1em;
  width: 100%;
  /* border: 1px solid gray; */
 

}

.PartnerContainer > h3{
  color: var(--secondary);
  width: 1040px;
  margin: 0;
  margin: auto;
  /* border: 1px solid gray; */

}

.PartnerBox{
  position:relative;
  /* overflow: hidden; */
  width: 1100px;
  margin: auto;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  /* border: 1px solid gray; */

}

.PartnerCard{
  transition: left 1s !important;
  transition-timing-function: ease !important;
  min-width: 250px;
  max-width: 250px;
  min-height: 250px;
  max-height: 250px;
  margin: 10px;
  cursor: pointer;
}

.Active{
    box-shadow : 5px 5px 5px var(--shadow2) !important;
}

.Card:hover {
  box-shadow:0px 6px 8px 0px  var(--primary);

}

.Card:hover > .Content > h3{
 color: var(--primary);
}

.Card > .Content > h3{
  margin-top: 0;
}

.Card > .Content > p{
  font-size: 11pt;
  line-height: 1.6;
  
}

.Datetime{
  font-size: 8pt;
  color: gray;  
}

/* information */
.ModalPartnerStyle{
  overflow: scroll
}

.ModalPartnerContainer{
  display: block;
  position: absolute;
  text-align: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  width: 700px;
  background-color: white;
  box-shadow: 24;
  /* border: 1px solid pink; */
  
}

.PartnerPhotoCarouselContainer{
  display: flex;
  position: relative;
  overflow: hidden;
  flex-wrap: nowrap;
  white-space: nowrap;
  width: 600px;
  /* border: 1px solid gray; */
  height: 500px;
  background-color: white;
  margin: auto;
  /* border: 1px solid pink; */

}

.PartnerPhoto{
  position: absolute;
  left: 0px;
  width: 600px;
  height: 500px;
  text-align: center;
  vertical-align: middle;
  transition: left 1s;
  transition-timing-function: ease;
  /* border: 1px solid pink; */
}

.ModalPartnerContainer > .ModalContent{
  padding: 1.5em;
}

.ModalPartnerContainer > .TitleBar{
  width: 100%;
  text-align: right;
}

.ModalContent > .ContentParagraph{

  white-space: pre-line;
  line-height: 1.6;

}
