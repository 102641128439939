
.InformationGalleryContainer{
  position: relative;
  display: flex;
  flex-flow: column;
  width: 1280px;
  /* 
  background: url('../assets/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  */
  padding-top: 1em;
  padding-bottom: 1em;
  /* border: 1px solid gray; */

}

.ControlContainer{
  display: flex;
  flex-flow: row;
  margin: auto;
  width: 100%;
  /* border: 1px solid gray; */

}

.InformationGalleryContainer > h3{
  color: var(--secondary);
  width: 1100px;
  margin: auto;
  padding: 1em;
}

.InformationGalleryBox{
  position:relative;
  width: 1100px;
  margin: auto;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content:flex-start;
  /* border: 1px solid gray; */
}

.GalleryCard{
  transition: left 1s !important;
  transition-timing-function: ease !important;
  min-width: 240px;
  max-width: 240px;
  min-height: 370px;
  max-height: 350px;
  margin: 10px;
  cursor: pointer;
}

.GalleryCard:hover {
  box-shadow:0px 6px 8px 0px  var(--primary);

}

.GalleryCard:hover > .Content > h3{
 color: var(--primary);
}

.GalleryCard > .Content > h3{
  margin-top: 0;
  font-size: 11pt;
}

.GalleryCard > .Content > p{
  font-size: 11pt;
  line-height: 1.6;
}

.GalleryCard > .Media{
    height: 200px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}


.ScheduleBox > .GalleryCard > .MediaContainer{
    display: block;
    width: 500px;
    height: 280px;
    /* border: 1px solid gray; */
}

.GalleryCard > .Content >.Datetime{
  margin-top: 3em;
  font-size: 7pt;
  color: gray;  
}
