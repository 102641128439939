
.VideoContainer{
  position: relative;
  display: flex;
  flex-flow: column;
  width: 1280px;
  height: 570px;
  background-color: white;
  /*
  background: url('../assets/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  */
  padding-top: 1em;
  padding-bottom: 1em;
  /* border: 1px solid gray; */

}

.ControlContainer{
  display: flex;
  flex-flow: row;
  margin: auto;
  width: 100%;
  /* border: 1px solid gray; */


}

.VideoContainer > h3{
  color: var(--secondary);
  width: 1040px;
  margin: auto;
  /* border: 1px solid gray; */

}

.VideoBox{
  position:relative;
  overflow: hidden;
  width: 1040px;
  height: 470px;
  margin: auto;
  display: flex;
  flex-flow: row;
  align-items: center;
  /* border: 1px solid gray; */

}

.VideoBox > .VideoCard{
  position:absolute;
  transition: left 1s !important;
  transition-timing-function: ease !important;
  min-width: 500px;
  max-width: 500px;
  min-height: 370px;
  max-height: 370px;
  margin: 10px;
  cursor: pointer;
  /* border: 1px solid gray; */

}

.VideoBox > .VideoCard > .MediaContainer{
    display: block;
    width: 500px;
    height: 280px;
    /* border: 1px solid gray; */
}

.VideoBox > .VideoCard > .MediaContainer > .PlayWatermark{
    position: absolute;
    z-index: 2;
    width: 100px;
    height: 100px;
    left: 50%;
    top: 25%;
    transform: translate(-50%, -25%);
    /* border: 1px solid gray; */

}

.VideoBox > .VideoCard > .MediaContainer > .VideoMedia{
    height: 280px;
    /* border: 1px solid gray; */

}

.VideoBox > .VideoCard > .Content{
}

.VideoBox > .VideoCard:hover {
  box-shadow:0px 6px 8px 0px  var(--primary);

}

.VideoBox > .VideoCard:hover h3{
 color: var(--primary);
}

.VideoBox > .VideoCard > .Content > h3{
  display: block;
  margin: 0;
}

.VideoBox > .VideoCard > .Content > p{
  font-size: 11pt;
  line-height: 1.4;
}

.VideoBox > .VideoCard > .Content > .VideoDatetime{
  display: block;
  margin-top: 1em;
  font-size: 9pt;
  color: gray;
}



/* video viewer */
.ModalVideoStyle{
  overflow: scroll
}

.ModalVideoContainer{
  display: block;
  position: absolute;
  text-align: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -30%);
  width: 800px;
  background-color: white;
  box-shadow: 24;

}

.VideoFrameContainer{
  display: flex;
  position: relative;
  overflow: hidden;
  flex-wrap: nowrap;
  white-space: nowrap;
  width: 800px;
  /* border: 1px solid gray; */
  height: 600px;
  background-color: white;
  margin: auto;
}


.VideoFrame{
    width: 1000px;
    height: inherit;
  }

.ModalVideoContainer > .ModalContent{
  padding: 1.5em;
}

.ModalVideoContainer > .TitleBar{
  width: 100%;
  text-align: right;
}

.ModalContent > .ContentParagraph{

  white-space: pre-line;
  line-height: 1.6;

}
