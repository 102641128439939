
.CareerContainer{
  position: relative;
  display: flex;
  flex-flow: column;
  width: 1280px;
  height: 720px;
  /* background-color: var(--shadow4); */
  /* 
  background: url('../assets/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  */
  padding-top: 1em;
  padding-bottom: 1em;
  /* border: 1px solid gray; */

}

.ControlContainer{
  display: flex;
  flex-flow: row;
  margin: auto;
  width: 100%;
  /* border: 1px solid gray; */
 

}

.CareerContainer > h1{
  color: var(--primary);
  width: 1040px;
  margin: auto;
  text-align: center;
  /* border: 1px solid gray; */

}

.CareerBox{
  position:relative;
  overflow: hidden;
  width: 1040px;
  height: 620px;
  margin: auto;
  display: flex;
  flex-flow: row;
  align-items: center;
  /* border: 1px solid gray; */

}

.CareerBox > .CareerCard{
  position:absolute;
  transition: left 1s !important;
  transition-timing-function: ease !important;
  min-width: 500px;
  max-width: 500px;
  min-height: 550px;
  max-height: 600px;
  margin: 10px;
  cursor: pointer;
  /* border: 1px solid gray; */

}

.CareerBox > .CareerCard > .MediaContainer{
    display: block;
    width: 500px;
    height: 500px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* border: 1px solid gray; */
}


.CareerBox > .CareerCard > .MediaContainer > .CareerMedia{
    height: 280px;
    background-size: 10px;
    border: 1px solid gray;

}

.CareerBox > .CareerCard > .Content{
}

.CareerBox > .CareerCard:hover {
  box-shadow:0px 6px 8px 0px  var(--primary);

}

.CareerBox > .CareerCard:hover h3{
 color: var(--primary);
}

.CareerBox > .CareerCard > .Content > h3{
  display: block;
  margin: 0;
  height: 2em;
  /* border: 1px solid gray; */
}

.CareerBox > .CareerCard > .Content > p{
  font-size: 11pt;
  line-height: 1.4;
}

.CareerBox > .CareerCard > .Content > .Datetime{
  display: block;
  margin-top: 1em;
  font-size: 9pt;
  color: gray;  
}



/* awards viewer */
.ModalCareerStyle{
  overflow: scroll
}

.ModalCareerContainer{
  display: block;
  position: absolute;
  text-align: left;
  left: 50%;
  top: 70%;
  transform: translate(-50%, -50%);
  width: 800px;
  background-color: white;
  box-shadow: 24;
  border: 1px solid gray;
  
}

.CareerMediaContainer{
    display: flex;
    position: relative;
    overflow: hidden;
    flex-wrap: nowrap;
    white-space: nowrap;
    width: 600px;
    /* border: 1px solid gray; */
    height: 600px;
    background-color: white;
    margin: auto;
    background-repeat: no-repeat;
    background-size: cover;
  }

.ModalCareerContainer > .ModalContent{
  padding: 1.5em;
}

.ModalCareerContainer > .TitleBar{
  width: 100%;
  text-align: right;
}

.ModalContent > .ContentParagraph{

  white-space: pre-line;
  line-height: 1.6;

}
